<template>
  <v-card>
    <action-bar
      :tender-id="tenderId"
      @add-container-to-parent="addContainer"
      @save-tender="save(false)"
      @admin-tender-preview="save(true)"
    ></action-bar>
    <div class="sticky-header-bar">
      <v-card-title
        class="d-flex justify-space-between"
      >
        <div>
          {{ $t('tender.create') }}
        </div>
        <div class="mt-3">
          <v-select
            v-if="tenderId !== null"
            v-model="tenderAction"
            dense
            outlined
            :label="$t('tender.tenderActions')"
            class="mr-2 float-left"
            :items="tenderActions"
            :item-text="item => $t(item.name)"
            item-value="key"
          ></v-select>
          <v-btn
            color="success"
            dark
            class="mr-2"
            @click.prevent="save(false)"
          >
            {{ $t('general.save') }}
          </v-btn>

          <router-link :to="{name:'admin-tender-list'}">
            <v-btn
              dark
              outlined
              color="error"
              class="mr-2"
            >
              {{ $t('general.close') }}
            </v-btn>
          </router-link>
        </div>
      </v-card-title>
    </div>

    <div>
      <v-stepper
        v-model="currentStep"
        flat
      >
        <TenderStepperHeader
          :current-step="currentStep"
          :icons="icons"
          :stepper-titles="stepperTitles"
          :steps="steps"
          :add-stepper-item="addStepperItem"
          :remove-step="removeStep"
        />

        <v-stepper-items>
          <v-stepper-content
            v-for="n in steps"
            :key="`${n}-content`"
            :step="n"
          >
            <v-card
              class="mb-12"
              height="auto"
              flat
            >
              <v-col v-if="n === 1">
                <div class="pt-6 mb-2 ">
                  <div class="w-full pl-2">
                    <h2>{{ $t('tender.mainData') }}</h2>
                  </div>
                  <v-row
                    cols="12"
                    class="pa-2"
                  >
                    <v-col
                      cols="12"
                      md="3"
                      lg="2"
                    >
                      <v-text-field
                        v-model="tenderDate"
                        disabled
                        dense
                        :label="$t('tender.date')"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="3"
                      lg="2"
                    >
                      <v-text-field
                        v-model="platformReferenceNumber"
                        disabled
                        dense
                        :label="$t('tender.platformReferenceNumber')"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="3"
                      lg="2"
                    >
                      <v-text-field
                        v-model="clientReferenceNumber"
                        dense
                        :label="$t('tender.clientReferenceNumber')"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="3"
                      lg="2"
                    >
                      <v-autocomplete
                        v-model="selectedCompany"
                        dense
                        outlined
                        :label="$t('tender.company')"
                        :items="companies"
                        item-text="name"
                        item-value="id"
                        clearable
                        type="search"
                        @update:search-input="filterCompanies"
                        @click:clear="clearCompany"
                      ></v-autocomplete>
                    </v-col>

                    <v-col
                      cols="12"
                      md="3"
                      lg="2"
                    >
                      <v-autocomplete
                        v-model="tenderOwner"
                        dense
                        outlined
                        :label="$t('tender.tenderOwner')"
                        :items="companyUsers"
                        item-text="name"
                        item-value="id"
                      ></v-autocomplete>
                    </v-col>

                    <v-col
                      cols="12"
                      md="3"
                      lg="2"
                    >
                      <v-select
                        v-model="status"
                        :label="$t('general.status')"
                        :items="$store.state.tenderStatuses"
                        item-value="key"
                        :item-text="item => $t(item.name)"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row
                    cols="12"
                    class="px-2"
                  >
                    <v-col
                      cols="12"
                      md="3"
                      lg="2"
                    >
                      <v-select
                        v-model="tenderCategory"
                        dense
                        outlined
                        :label="$t('tender.tenderCategory')"
                        :items="tenderCategories"
                        item-value="key"
                        :item-text="item => $t(item.name)"
                      ></v-select>
                    </v-col>

                    <v-col
                      cols="12"
                      md="3"
                      lg="2"
                    >
                      <v-select
                        v-model="platformCategory"
                        :label="$t('tender.platformCategory')"
                        :items="$store.state.platformCategories"
                        dense
                        outlined
                        item-value="key"
                        :item-text="item => $t(item.name)"
                      >
                      </v-select>
                    </v-col>

                    <v-col
                      cols="12"
                      md="3"
                      lg="2"
                    >
                      <v-select
                        v-model="procedureType"
                        :label="$t('tender.procedureType')"
                        :items="$store.state.procedureType"
                        dense
                        outlined
                        item-value="key"
                        :item-text="item => $t(item.name)"
                      >
                      </v-select>
                    </v-col>

                    <v-col
                      cols="12"
                      md="3"
                      lg="2"
                    >
                      <v-radio-group
                        v-model="stateAreaContract"
                        :label="$t('tender.tenderTypeStateAreaContract')"
                        row
                      >
                        <v-radio
                          value="yes"
                          :label="$t('yes')"
                        >
                        </v-radio>
                        <v-radio
                          value="no"
                          :label="$t('no')"
                        >
                        </v-radio>
                      </v-radio-group>
                    </v-col>

                    <v-col
                      cols="12"
                      md="3"
                      lg="2"
                    >
                      <v-menu
                        v-model="acceptingOffersFromPickerHandler"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="acceptingOffersFrom"
                            :label="$t('tender.acceptingOffersFrom')"
                            :hint="$t('tender.dateFormatHint')"
                            persistent-hint
                            :append-icon="icons.mdiCalendar"
                            readonly
                            dense
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>

                        <v-date-picker
                          v-model="acceptingOffersFrom"
                          no-title
                          color="primary"
                          @input="acceptingOffersFromPickerHandler = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                      lg="2"
                    >
                      <v-menu
                        v-model="acceptingOffersToPickerHandler"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="acceptingOffersTo"
                            :label="$t('tender.acceptingOffersTo')"
                            :hint="$t('tender.dateFormatHint')"
                            persistent-hint
                            :append-icon="icons.mdiCalendar"
                            readonly
                            dense
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>

                        <v-date-picker
                          v-model="acceptingOffersTo"
                          no-title
                          color="primary"
                          @input="acceptingOffersToPickerHandler = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <div class="w-full pl-2 pb-4 d-flex justify-space-between">
                    <h2>{{ $t('tender.addressData') }}</h2>

                    <v-btn
                      icon
                      dark
                      outlined
                      :color="selectedCompany ? 'primary' : 'secondary'"
                      class="mx-2"
                      @click.prevent="addAddressDialog"
                    >
                      <v-icon>{{ icons.mdiPlus }}</v-icon>
                    </v-btn>
                  </div>

                  <v-row
                    cols="12"
                    class="px-2 py-0"
                  >
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      {{ $t('tender.mainCompanyAddress') }}
                    </v-col>

                    <v-col
                      cols="12"
                      md="2"
                      lg="3"
                    >
                      <v-text-field
                        v-model="company.name"
                        dense
                        disabled
                        :label="$t('companies.name')"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="2"
                      lg="3"
                    >
                      <v-text-field
                        v-model="company.address"
                        dense
                        disabled
                        :label="$t('tender.address')"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="2"
                      lg="2"
                    >
                      <v-text-field
                        v-model="company.zipCode"
                        dense
                        disabled
                        :label="$t('tender.zipCode')"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="2"
                      lg="2"
                    >
                      <v-text-field
                        v-model="company.city"
                        dense
                        disabled
                        :label="$t('tender.city')"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="2"
                      lg="2"
                    >
                      <v-text-field
                        v-model="company.country"
                        dense
                        disabled
                        :label="$t('tender.country')"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row
                    v-for="(address, index) in addresses"
                    :key="index"
                    cols="12"
                    class="px-2 py-0 position-relative"
                  >
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      {{ $t($store.state.addressTypeOptions.filter(
                        item =>item.key === address.type
                      )[0].name) }}
                    </v-col>

                    <v-col
                      cols="12"
                      md="2"
                      lg="2"
                    >
                      <v-text-field
                        v-model="address.contact.name"
                        dense
                        disabled
                        :label="$t('companies.name')"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="2"
                      lg="2"
                    >
                      <v-text-field
                        v-model="address.contact.address"
                        dense
                        disabled
                        :label="$t('tender.address')"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="2"
                      lg="2"
                    >
                      <v-text-field
                        v-model="address.contact.zip_code"
                        dense
                        disabled
                        :label="$t('tender.zipCode')"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="2"
                      lg="2"
                    >
                      <v-text-field
                        v-model="address.contact.city"
                        dense
                        disabled
                        :label="$t('tender.city')"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="2"
                      lg="2"
                    >
                      <v-text-field
                        v-model="address.contact.country"
                        dense
                        disabled
                        :label="$t('tender.country')"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="2"
                      lg="2"
                    >
                      <v-text-field
                        v-model="address.contact.phone"
                        dense
                        disabled
                        :label="$t('companies.phone')"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-btn
                      :title="$t('tender.removeAddress')"
                      icon
                      absolute
                      color="error"
                      class="top-action-icon"
                      @click.prevent="removeAddress(address.id)"
                    >
                      <v-icon>
                        {{ icons.mdiCloseCircle }}
                      </v-icon>
                    </v-btn>
                  </v-row>

                  <div class="w-full pl-2">
                    <h2>{{ $t('tender.title') }}</h2>
                  </div>

                  <v-col cols="12">
                    <v-text-field
                      v-model="tenderTitle"
                      dense
                      :label="$t('tender.mainTitle')"
                      outlined
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="internalTitle"
                      dense
                      :label="$t('tender.internalTitle')"
                      outlined
                    ></v-text-field>
                  </v-col>

                  <div class="w-full pl-2">
                    <h2>{{ $t('tender.tenderDescription') }}</h2>
                  </div>

                  <v-row
                    cols="12"
                    class="no-gutters px-2"
                  >
                    <v-textarea
                      v-model="mainDescription"
                      dense
                      :label="$t('tender.descriptionField')"
                      outlined
                    ></v-textarea>
                  </v-row>
                </div>
              </v-col>

              <div
                v-for="(formItem, index) in formItems"
                :key="index"
              >
                <div
                  v-if="formItem.stepperId === n"
                  class="w-full pt-6 pa-2 mb-2 "
                >
                  <div
                    class="container-divider"
                  >
                    <v-divider></v-divider>
                    <div>C-{{ formItem.containerId }}</div>
                  </div>

                  <v-row>
                    <v-col
                      cols="12"
                      md="2"
                      lg="2"
                    >
                      <v-btn
                        :title="$t('tender.moveDown')"
                        fab
                        dark
                        outlined
                        small
                        :color="index !== formItems.length - 1 && formItems[index].stepperId === formItems[index + 1].stepperId ?
                          'info' :
                          'secondary'"
                        class="top-action-icon mx-1 my-3"
                        @click.prevent="moveContainerDown(index)"
                      >
                        <v-icon>
                          {{ icons.mdiArrowDown }}
                        </v-icon>
                      </v-btn>
                      <v-btn
                        :title="$t('tender.moveUp')"
                        fab
                        dark
                        outlined
                        small
                        :color="index !== 0 && formItems[index].stepperId === formItems[index - 1].stepperId ?
                          'info' :
                          'secondary'"
                        class="top-action-icon ml-1 mr-3 my-3"
                        @click.prevent="moveContainerUp(index)"
                      >
                        <v-icon>
                          {{ icons.mdiArrowUp }}
                        </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="12"
                      md="8"
                      lg="7"
                    >
                      <v-text-field
                        v-model="formItem.containerName"
                        :label="formItem.containerName ? '' : $t('tender.dataContainer') + (index + 1)"
                        style="font-weight: bold; font-size: 120%;"
                        outlined
                        :append-icon="icons.mdiPencilCircle"
                        color="primary"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                      lg="3"
                      class="text-lg-right"
                    >
                      <v-btn
                        dark
                        outlined
                        color="primary"
                        class="ml-1 py-7"
                        @click.prevent="addFieldsDialog(index)"
                      >
                        {{ $t('tender.addRow') }}
                      </v-btn>
                      <v-btn
                        dark
                        outlined
                        color="error"
                        class="mx-1 py-7"
                        @click.prevent="removeContainer(index)"
                      >
                        {{ $t('tender.remove') }}
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="col-12">
                      <h2>
                      </h2>
                    </v-col>

                    <v-col
                      v-if="formItem.subtitle !== undefined"
                      cols="12"
                      style="position: relative"
                    >
                      <v-text-field
                        v-model="formItem.subtitle"
                        dense
                        :label="$t('tender.containerSubtitle')"
                        outlined
                      ></v-text-field>

                      <v-btn
                        :title="$t('tender.removeSubtitle')"
                        icon
                        absolute
                        color="error"
                        class="top-action-icon"
                        @click.prevent="removeSubtitle(index)"
                      >
                        <v-icon>
                          {{ icons.mdiCloseCircle }}
                        </v-icon>
                      </v-btn>
                    </v-col>

                    <v-col
                      v-if="formItem.description !== undefined"
                      cols="12"
                      style="position: relative"
                    >
                      <v-textarea
                        v-model="formItem.description"
                        dense
                        :label="$t('tender.containerDescription')"
                        outlined
                      ></v-textarea>

                      <v-btn
                        :title="$t('tender.removeDescription')"
                        icon
                        absolute
                        color="error"
                        class="top-action-icon"
                        @click.prevent="removeDescription(index)"
                      >
                        <v-icon>
                          {{ icons.mdiCloseCircle }}
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>

                  <ConfiguratorForm>
                    <v-row
                      v-for="(formRow, row) in formItem.rows"
                      :key="row"
                      style="border: 1px solid lightgray;padding: 0;margin: 3px 0;border-radius: 5px"
                    >
                      <v-radio-group
                        :v-model="(formItem.radioGroup + index + row)"
                        row
                        class="w-full my-0"
                      >
                        <v-col
                          v-for="(item, col) in formRow"
                          :key="col"
                          cols="12"
                          :md="item.type !== 'textarea' ? 4 : 12"
                          :lg="item.type !== 'textarea' ? 2 : 12"
                          style="position: relative"
                        >
                          <v-text-field
                            v-if="item.type === 'text' || item.type === 'number'"
                            v-model="item.value"
                            :type="item.type"
                            :label="item.label"
                            dense
                            outlined
                          >
                          </v-text-field>

                          <v-file-input
                            v-if="item.type === 'file'"
                            ref="files"
                            :type="item.type"
                            :label="item.label"
                            dense
                            outlined
                            accept=".jpg,.png,.pdf,.gif,.jpeg,.xls,.xlsx"
                            @change="handleFilesUpload($event, index, row, col)"
                          >
                          </v-file-input>

                          <v-radio
                            v-if="item.type === 'radio'"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            dense
                          >
                          </v-radio>

                          <v-checkbox
                            v-if="item.type === 'checkbox'"
                            v-model="item.value"
                            :type="item.type"
                            :label="item.label"
                            outlined
                            class="mt-2"
                          >
                          </v-checkbox>

                          <v-textarea
                            v-if="item.type === 'textarea'"
                            v-model="item.value"
                            :type="item.type"
                            :label="item.label"
                            dense
                            outlined
                          >
                          </v-textarea>

                          <v-menu
                            v-if="item.type === 'date'"
                            v-model="item.isSelected"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="item.value"
                                :label="item.label"
                                hint="MM/DD/YYYY format"
                                persistent-hint
                                :prepend-icon="icons.mdiCalendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>

                            <v-date-picker
                              v-model="item.value"
                              no-title
                              color="primary"
                              @input="item.isSelected = false"
                            ></v-date-picker>
                          </v-menu>

                          <v-btn
                            :title="$t('tender.removeField')"
                            icon
                            absolute
                            color="error"
                            class="top-action-icon"
                            @click.prevent="removeField(index, row, col)"
                          >
                            <v-icon>
                              {{ icons.mdiCloseCircle }}
                            </v-icon>
                          </v-btn>

                          <v-btn
                            :title="$t('tender.editField')"
                            icon
                            absolute
                            color="primary"
                            class="bottom-action-icon"
                            @click.prevent="editField(index, row, col, item.type, item.label, item.isRequested)"
                          >
                            <v-icon>
                              {{ icons.mdiPencilCircle }}
                            </v-icon>
                          </v-btn>
                        </v-col>

                        <v-col
                          cols="12"
                          md="2"
                          lg="2"
                          class="text-left"
                        >
                          <v-btn
                            :title="$t('tender.addField')"
                            fab
                            dark
                            outlined
                            small
                            color="primary"
                            class="mr-1 text-xl"
                            @click.prevent="addField(index, row)"
                          >
                            <v-icon>{{ icons.mdiPlus }}</v-icon>
                          </v-btn>

                          <v-btn
                            :title="$t('tender.removeRow')"
                            fab
                            outlined
                            dark
                            small
                            color="error"
                            class="text-xl"
                            @click.prevent="removeRow(index, row)"
                          >
                            <v-icon>{{ icons.mdiMinus }}</v-icon>
                          </v-btn>
                        </v-col>
                      </v-radio-group>
                    </v-row>
                  </ConfiguratorForm>
                </div>
              </div>
            </v-card>

            <v-row class="text-center">
              <v-col
                cols="12"
                lg="4"
                md="4"
              ></v-col>
              <v-col
                cols="12"
                lg="4"
                md="4"
              >
                <v-btn
                  dark
                  outlined
                  color="primary"
                  @click.prevent="addContainer(n)"
                >
                  {{ $t('tender.addContainer') }}
                </v-btn>
              </v-col>

              <v-col
                cols="12"
                lg="4"
                md="4"
                class="text-lg-right"
              >
                <v-btn
                  color="success"
                  dark
                  class="mr-2"
                  @click.prevent="save(false)"
                >
                  {{ $t('general.save') }}
                </v-btn>

                <router-link :to="{name:'admin-tender-list'}">
                  <v-btn
                    dark
                    outlined
                    color="error"
                    class="mr-2"
                  >
                    {{ $t('general.close') }}
                  </v-btn>
                </router-link>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
        <TenderStepperHeader
          :current-step="currentStep"
          :icons="icons"
          :stepper-titles="stepperTitles"
          :steps="steps"
          :add-stepper-item="addStepperItem"
          :remove-step="removeStep"
        />
      </v-stepper>
    </div>

    <v-col cols="12">
      <!-- remove container dialog -->
      <RemoveContainerDialog
        :close-remove-container-dialog="closeRemoveContainerDialog"
        :remove-container-confirm="removeContainerConfirm"
        :remove-container-dialog="removeContainerDialog"
      />

      <!-- add another row dialog -->
      <AddRowDialog
        :close-fields-dialog="closeFieldsDialog"
        :input-field-label="inputFieldLabel"
        :input-field-option="inputFieldOption"
        :is-requested="isRequested"
        :input-type-dialog="inputTypeDialog"
        @input-field-type-selected="addFieldConfirm"
      />

      <!-- add address dialog -->
      <AddAddressDialog
        :key="tenderService.generateRandomNumber()"
        :add-address-confirm="addAddressConfirm"
        :address-type="addressType"
        :company="selectedCompany"
        :address-type-options="addressOptions"
        :close-addresses-dialog="closeAddressesDialog"
        :show-address-dialog="showAddressDialog"
        @added-additional-address="addAddressConfirm"
      />

      <v-dialog
        v-model="editFieldDialog"
        max-width="800px"
      >
        <v-card>
          <v-card-title>
            {{ $t('tender.inputFieldType') }}
          </v-card-title>
          <v-row class="no-gutters pa-5">
            <v-col
              lg="6"
              md="6"
              cols="12"
              class="pa-2"
            >
              <v-text-field
                v-model="inputFieldLabel"
                dense
                outlined
                :label="$t('tender.fieldLabel')"
              ></v-text-field>
            </v-col>
            <v-col
              lg="6"
              md="6"
              cols="12"
              class="pa-2"
            >
              <v-select
                v-model="inputFieldOption"
                dense
                outlined
                :label="$t('tender.chooseInputType')"
                :items="inputFieldOptions"
                :item-text="item => $t(item.name)"
                item-value="key"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
            >
              <v-checkbox
                v-model="isRequested"
                :label="$t('tender.dataRequested')"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="closeEditDialog"
            >
              {{ $t('general.cancel') }}
            </v-btn>
            <v-btn
              color="success"
              @click="editFieldConfirm()"
            >
              {{ $t('general.ok') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="showStepperModal"
        max-width="800px"
      >
        <v-card>
          <v-card-title>
            {{ $t('tender.enterStepperTitle') }}
          </v-card-title>
          <v-row class="no-gutters pa-5">
            <v-col
              cols="12"
              class="pa-2"
            >
              <v-text-field
                v-model="stepperTitle"
                dense
                outlined
                :label="$t('tender.stepperTitle')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="showStepperModal = false"
            >
              {{ $t('general.cancel') }}
            </v-btn>
            <v-btn
              color="success"
              @click="addStepperItemConfirm()"
            >
              {{ $t('general.ok') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="showEditStepperModal"
        max-width="800px"
      >
        <v-card>
          <v-card-title>
            {{ $t('tender.enterStepperTitle') }}
          </v-card-title>
          <v-row class="no-gutters pa-5">
            <v-col
              cols="12"
              class="pa-2"
            >
              <v-text-field
                v-model="stepperTitle"
                dense
                outlined
                :label="$t('tender.stepperTitle')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="closeEditStepperItemModal"
            >
              {{ $t('general.cancel') }}
            </v-btn>
            <v-btn
              color="success"
              @click="editStepperItemConfirm()"
            >
              {{ $t('general.ok') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>

    <v-snackbar
      v-model="snackbar"
    >
      {{ $t('tender.dataSaved') }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('general.close' ) }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import {
  mdiArrowDown,
  mdiArrowUp,
  mdiCalendar,
  mdiCircleEditOutline,
  mdiCloseCircle,
  mdiCloseOutline,
  mdiMinus,
  mdiPencilCircle,
  mdiPlus,
} from '@mdi/js'
import axiosIns from '@axios'
import ConfiguratorForm from '@/views/admin/Tender/components/Form.vue'
import ActionBar from '@/views/admin/Tender/components/ActionBar.vue'
import RemoveContainerDialog from '@/views/admin/Tender/components/RemoveContainerDialog.vue'
import AddRowDialog from '@/views/admin/Tender/components/AddRowDialog.vue'
import AddAddressDialog from '@/views/admin/Tender/components/AddAddressDialog.vue'
import fieldOptions from '@/views/admin/Tender/fieldOptions'
import tenderService from '@/views/admin/Tender/tenderService'
import TenderStepperHeader from '@/views/admin/Tender/components/TenderStepperHeader.vue'

export default {

  components: {
    TenderStepperHeader,
    AddAddressDialog,
    AddRowDialog,
    RemoveContainerDialog,
    ActionBar,
    ConfiguratorForm,
  },
  data() {
    const stateAreaContract = 'no'
    const addresses = []
    const deletedAddresses = []
    const status = 'draft'
    const tenderId = null
    const snackbar = false
    const files = []
    const selectedStepperItem = null
    const stepperTitle = ''
    const showStepperModal = false
    const showEditStepperModal = false
    const stepperTitles = [{ title: this.$t('tender.step1') }]
    const platformCategory = null
    const procedureType = null
    const acceptingOffersFromPickerHandler = false
    const acceptingOffersFrom = ''
    const acceptingOffersToPickerHandler = false
    const acceptingOffersTo = ''
    const isInternationalTender = false
    const dateMenu = false
    const inputFieldOptions = fieldOptions

    const selectedCompany = ''
    const company = {}
    const companies = []
    const companyUsers = []

    const addressType = null
    const showAddressDialog = false
    const currentContainerIndex = null
    const currentRowIndex = null
    const currentColIndex = null
    const inputFieldOption = null
    const inputFieldLabel = null
    const isRequested = false

    const editFieldDialog = false
    const inputTypeDialog = false
    const removeContainerDialog = false
    const tenderOwner = ''
    const internalTitle = ''
    const formItems = [{
      stepperId: 1,
      containerId: tenderService.generateRandomNumber(),
      containerName: '',
      subtitle: '',
      description: '',
      rows: [
        [
        ],
      ],
    },
    ]

    const tenderTitle = ''
    const mainDescription = ''
    const clientReferenceNumber = ''

    const { tenderCategories } = this.$store.state
    const { tenderActions } = this.$store.state
    const { addressOptions } = this.$store.state

    return {
      tenderService,
      stateAreaContract,
      deletedAddresses,
      addresses,
      status,
      tenderId,
      snackbar,
      files,
      selectedStepperItem,
      stepperTitle,
      showStepperModal,
      showEditStepperModal,
      stepperTitles,
      platformCategory,
      procedureType,
      selectedCompany,
      currentStep: 1,
      steps: 1,
      acceptingOffersToPickerHandler,
      acceptingOffersTo,
      acceptingOffersFromPickerHandler,
      acceptingOffersFrom,
      isInternationalTender,
      inputFieldOptions,
      addressOptions,
      addressType,
      showAddressDialog,
      dateMenu,
      currentContainerIndex,
      currentRowIndex,
      currentColIndex,
      inputFieldLabel,
      inputFieldOption,
      isRequested,
      company,
      companies,
      editFieldDialog,
      inputTypeDialog,
      removeContainerDialog,
      internalTitle,
      tenderOwner,
      companyUsers,
      formItems,
      tenderAction: '',
      tenderTitle,
      mainDescription,
      tenderDate: '',
      platformReferenceNumber: '',
      clientReferenceNumber,
      tenderCategories,
      tenderCategory: null,
      tenderActions,
      icons: {
        mdiCloseOutline,
        mdiCircleEditOutline,
        mdiPencilCircle,
        mdiCloseCircle,
        mdiCalendar,
        mdiPlus,
        mdiMinus,
        mdiArrowUp,
        mdiArrowDown,
      },
    }
  },

  watch: {
    steps(val) {
      if (this.currentStep > val) {
        this.currentStep = val
      }
    },

    tenderAction() {
      if (this.tenderAction === 'preview') {
        this.save(true)
      }
      if (this.tenderAction === 'preview') {
        this.$router.push({ name: 'admin-tender-view', params: { id: this.tenderId } })
      }
      if (this.tenderAction === 'delete') {
        axiosIns.delete(`admin/tenders/${this.tenderId}`)
          .then(() => {
            this.$router.push({ name: 'admin-tender-list' })
          })
          .catch()
      }
      if (this.tenderAction === 'edit') {
        this.$router.push({ name: 'admin-tender-edit', params: { id: this.tenderId } })
      }
    },

    selectedCompany() {
      if (this.selectedCompany) {
        const company = this.companies.filter(item => item.id === this.selectedCompany)[0]
        this.company = {
          id: company.id,
          name: company.name,
          address: company.address,
          zipCode: company.zip_code,
          city: company.city,
          country: company.country,
        }

        axiosIns.get(`/admin/users?companyId=${this.selectedCompany}`)
          .then(res => {
            this.companyUsers = res.data.users
          })
          .catch()
      } else {
        this.companyUsers = []
        this.tenderOwner = null
        this.company = {}
      }
    },

  },

  created() {
    this.clearCompany = tenderService.clearCompany.bind(this)
    this.filterCompanies = tenderService.filterCompanies.bind(this)
    this.moveContainerDown = tenderService.moveContainerDown.bind(this)
    this.moveContainerUp = tenderService.moveContainerUp.bind(this)
    this.addContainer = tenderService.addContainer.bind(this)
    this.editFieldConfirm = tenderService.editFieldConfirm.bind(this)
    this.editField = tenderService.editField.bind(this)
    this.closeEditDialog = tenderService.closeEditDialog.bind(this)
    this.addFieldConfirm = tenderService.addFieldConfirm.bind(this)
    this.addStepperItem = tenderService.addStepperItem.bind(this)
    this.addStepperItemConfirm = tenderService.addStepperItemConfirm.bind(this)
    this.removeStep = tenderService.removeStep.bind(this)
    this.editStep = tenderService.editStep.bind(this)
    this.editStepperItemConfirm = tenderService.editStepperItemConfirm.bind(this)
    this.closeEditStepperItemModal = tenderService.closeEditStepperItemModal.bind(this)
    this.addAddressConfirm = tenderService.addAddressConfirm.bind(this)
    this.addAddressDialog = tenderService.addAddressDialog.bind(this)
    this.closeAddressesDialog = tenderService.closeAddressesDialog.bind(this)
    this.removeAddress = tenderService.removeAddress.bind(this)
    this.closeRemoveContainerDialog = tenderService.closeRemoveContainerDialog.bind(this)
    this.removeContainer = tenderService.removeContainer.bind(this)
    this.removeContainerConfirm = tenderService.removeContainerConfirm.bind(this)
    this.addFieldsDialog = tenderService.addFieldsDialog.bind(this)
    this.closeFieldsDialog = tenderService.closeFieldsDialog.bind(this)
    this.removeRow = tenderService.removeRow.bind(this)
    this.addField = tenderService.addField.bind(this)
    this.removeField = tenderService.removeField.bind(this)
    this.handleFilesUpload = tenderService.handleFilesUpload.bind(this)
  },

  methods: {

    removeSubtitle(index) {
      delete this.formItems[index].subtitle

      /* reactivate from */
      this.formItems.push()
    },

    removeDescription(index) {
      delete this.formItems[index].description

      /* reactivate from */
      this.formItems.push()
    },

    save(redirectToThePreview = false) {
      const tender = new FormData()

      tender.append('tender_date', this.tenderDate)
      tender.append('platform_reference_number', this.platformReferenceNumber)
      tender.append('client_reference_number', this.clientReferenceNumber)
      tender.append('tender_category', this.tenderCategory)
      tender.append('procedure_type', this.procedureType)
      tender.append('company_id', this.selectedCompany)
      tender.append('owner_id', this.tenderOwner)
      tender.append('status', this.status)
      tender.append('title', this.tenderTitle)
      tender.append('internal_title', this.internalTitle)
      tender.append('description', this.mainDescription)
      tender.append('accepting_offers_from_date', this.acceptingOffersFrom)
      tender.append('accepting_offers_to_date', this.acceptingOffersTo)
      tender.append('platform_category', this.platformCategory)
      tender.append('procedure_type', this.procedureType)
      tender.append('state_area_contract', this.stateAreaContract)
      tender.append('tender_containers', JSON.stringify(this.formItems))
      tender.append('steps', this.steps)
      tender.append('stepper_titles', JSON.stringify(this.stepperTitles))
      tender.append('addresses', JSON.stringify(this.addresses))
      tender.append('deleted_addresses', JSON.stringify(this.deletedAddresses))

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.files.length; i++) {
        const { file } = this.files[i]
        tender.append(`files[${this.files[i].id}]`, file)
      }

      axiosIns({
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: tender,
        url: this.tenderId ? `/admin/tenders/${this.tenderId}` : '/admin/tenders',
      })
        .then(res => {
          this.snackbar = true
          this.tenderId = res.data.tender.id
          this.platformReferenceNumber = res.data.tender.platform_reference_number
          this.tenderDate = res.data.tender.tender_date
          this.addresses = res.data.tender.tender_contacts
          if (redirectToThePreview) {
            this.$router.push({ name: 'admin-tender-view', params: { id: this.tenderId } })
          }
        })
        .catch(err => { console.log(err) })
    },

  },
}

</script>
<style scoped lang="scss">

</style>
